import React from "react"
import { graphql } from "gatsby"

import Content, { HTMLContent } from "../components/Content"
import Layout, { Section } from "../components/Layout"
import PageImage from "../components/PageUnsplashImage"
import { ContactForm } from "../components/ContactForm"
import { WeeklyEvent, MonthlyEvents, EventSection } from "../components/Events"
import { isPastDate } from "../components/Date"

export const EventsPageTemplate = ({
  title,
  content,
  image,
  imageCredit,
  contentComponent,
  location,
  weeklyEvents,
  monthlyEvents,
}) => {
  const PageContent = contentComponent || Content
  const formVariation = location.pathname.replace("/", "")

  const events = monthlyEvents.map((e) => e.node)
  const pastEvents = events.filter((e) => isPastDate(e.frontmatter.date))
  const futureEvents = events.filter((e) => !isPastDate(e.frontmatter.date))

  return (
    <div>
      <PageImage src={image} credit={imageCredit}>
        <h1 className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">
          {title}
        </h1>
      </PageImage>

      <Section className="container is-max-desktop">

        <EventSection id="atividades-proximas" title="Próximos Eventos">
          <MonthlyEvents events={futureEvents} desc={false} />
        </EventSection>

        <EventSection id="atividades-mensais" title="Eventos Passados">
          {weeklyEvents.map((event) => (
            <WeeklyEvent event={event.node} />
          ))}
          <MonthlyEvents events={pastEvents} />
        </EventSection>

        <PageContent className={["content markdown"].join(" ")} content={content} />

        <ContactForm variation={formVariation} />
      </Section>
    </div>
  )
}

const EventsPage = ({ data, location }) => {
  const page = data.page
  const weeklyEvents = data.weeklyEvents.list
  const monthlyEvents = data.monthlyEvents.list

  return (
    <Layout title={page.frontmatter.title}>
      <EventsPageTemplate
        location={location}
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        content={page.html}
        image={page.frontmatter.image}
        imageCredit={page.frontmatter.imageCredit}
        weeklyEvents={weeklyEvents}
        monthlyEvents={monthlyEvents}
      />
    </Layout>
  )
}

export default EventsPage

export const query = graphql`
  query EventsPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        imageCredit {
          author
          href
        }
        image
      }
    }
    weeklyEvents: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "weekly-event-page" } } }
    ) {
      list: edges {
        node {
          html
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            notes
            when
            link
            linkText
            promoImage
          }
        }
      }
    }
    monthlyEvents: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "monthly-event-page" } } }
    ) {
      list: edges {
        node {
          html
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            notes
            date
            dateOverride
            startTime
            endTime
            form
            youtubeURL
            confirmed
            link
            linkText
            promoImage
          }
        }
      }
    }
  }
`
